import React from 'react';
import './styles.scss';

import './styles.scss';

const Admin = props => {
    return (

        <div className='admin'>
            <h2>
                My Admin
            </h2>
        </div>
    );
};

export default Admin;
