import React from 'react';
import './styles.scss';
import SignIn from './../../components/SignIn';

const Login = props => {
  return (

           <SignIn />  

  );

}

export default Login;