// firebase config
export const firebaseConfig = {

        apiKey: "AIzaSyB2gSQbRUlqszXlIdhO7P2-5OEPd-wSNR4",
        authDomain: "godashing-bc27e.firebaseapp.com",
        databaseURL: "https://godashing-bc27e.firebaseio.com",
        projectId: "godashing-bc27e",
        storageBucket: "godashing-bc27e.appspot.com",
        messagingSenderId: "787847986950",
        appId: "1:787847986950:web:f9b8692c47ff3ab2b0e887"

};
